.home__about{
    margin-top: 80px;
}
.home__about_div{
    padding: 60px 0px;
    width: 600px 
}

.home__about_div_h5{
text-align: center;
color: gray;
}
.home__about_div_h1{
    font-weight: bold;
    text-align: center;
}
.home__about_div_p{
    font-size: 20px;
    margin-top: 30px;
    text-align: justify;
}
