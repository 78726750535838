.home__team {
    margin-top: 80px;
}
.home__team_p {
    color: grey;
    text-align: center;
}
.home__team_img {
    width: 280px;
    height: 180px;
}

.home__team_img_bigger {
    width: 500px;

}
.home__team_p_position{
    color: grey;
}