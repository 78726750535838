.home__topnft {
    margin-top: 80px;
}
.home__topnft_p {
    color: grey;
    text-align: center;
}

.home__topnft_img {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.rounded_div {
    border-radius: 10px;
}

.ant-modal-body {
    height: calc(70vh - 100px);
}